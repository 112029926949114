<template>
  <div class="m-pg-sub">
    <page-cover layer="rgba(0,0,0,.2)">
      <!-- <h2 class="m-page-title">{{$t('page.search.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.search.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <tab-nav
        :data="tabNavData"
        :active.sync="tabNavActiveUrl">
      </tab-nav>
      <div class="child-main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from '@/components/tab-nav'
export default {
  components: {
    TabNav
  },
  data() {
    return {
      tabNavData: [
        {name: this.$t('page.search.m_tab.composition'), url: '/search/composition'},
        {name: this.$t('page.search.m_tab.keyword'), url: '/search/keyword'},
        // {name: this.$t('page.search.m_tab.visualized'), url: '/visualized'},
        // {name: this.$t('page.search.m_tab.topic'), url: '/search/topic'}
      ],
      tabNavActiveUrl: '/search/composition'
    }
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    .child-main {
      // padding: 20px;
      // background-color: #fff;
    }
  }
</style>
